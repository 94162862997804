import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// GEN_REGISTER_COMPONENT_STAR
Vue.component('puidocumentgrid', () => import('pui9-documents/src/components/PuiDocumentGrid.vue'));
Vue.component('puidocumentform', () => import('pui9-documents/src/components/PuiDocumentForm.vue'));
Vue.component('pui-text-area2', () => import('@/components//baseextended/PuiTextArea2.vue'));
Vue.component('mimdg-grid', () => import('@/components/mimdg/MimdgGrid'));
Vue.component('mimdg-grid', () => import('@/components/mimdg/MimdgGrid'));
Vue.component('mimdgformheader', () => import('@/components/mimdg/MimdgFormHeader'));
Vue.component('magente-grid', () => import('@/components/magente/MagenteGrid'));
Vue.component('magente-form', () => import('@/components/magente/MagenteForm'));
Vue.component('magente-form-header', () => import('@/components/magente/MagenteFormHeader'));
Vue.component('mpais-grid', () => import('@/components/mpais/MpaisGrid'));
Vue.component('mpais-form', () => import('@/components/mpais/MpaisForm'));
Vue.component('mpais-form-header', () => import('@/components/mpais/MpaisFormHeader'));
Vue.component('mtiporesolucion-grid', () => import('@/components/mtiporesolucion/MtiporesolucionGrid'));
Vue.component('mtiporesolucion-form', () => import('@/components/mtiporesolucion/MtiporesolucionForm'));
Vue.component('mtiporesolucion-form-header', () => import('@/components/mtiporesolucion/MtiporesolucionFormHeader'));
Vue.component('mgrupoenvasado-grid', () => import('@/components/mgrupoenvasado/MgrupoenvasadoGrid'));
Vue.component('mgrupoenvasado-form', () => import('@/components/mgrupoenvasado/MgrupoenvasadoForm'));
Vue.component('mgrupoenvasado-form-header', () => import('@/components/mgrupoenvasado/MgrupoenvasadoFormHeader'));
Vue.component('mareaoperacion-grid', () => import('@/components/mareaoperacion/MareaoperacionGrid'));
Vue.component('mareaoperacion-form', () => import('@/components/mareaoperacion/MareaoperacionForm'));
Vue.component('mareaoperacion-form-header', () => import('@/components/mareaoperacion/MareaoperacionFormHeader'));
Vue.component('minstmarcaje-grid', () => import('@/components/minstmarcaje/MinstmarcajeGrid'));
Vue.component('minstmarcaje-form', () => import('@/components/minstmarcaje/MinstmarcajeForm'));
Vue.component('minstmarcaje-form-header', () => import('@/components/minstmarcaje/MinstmarcajeFormHeader'));
Vue.component('mlocalidad-grid', () => import('@/components/mlocalidad/MlocalidadGrid'));
Vue.component('mlocalidad-form', () => import('@/components/mlocalidad/MlocalidadForm'));
Vue.component('mlocalidad-form-header', () => import('@/components/mlocalidad/MlocalidadFormHeader'));
Vue.component('mtipobulto-grid', () => import('@/components/mtipobulto/MtipobultoGrid'));
Vue.component('mtipobulto-form', () => import('@/components/mtipobulto/MtipobultoForm'));
Vue.component('mtipobulto-form-header', () => import('@/components/mtipobulto/MtipobultoFormHeader'));
Vue.component('mtipocarga-grid', () => import('@/components/mtipocarga/MtipocargaGrid'));
Vue.component('mtipocarga-form', () => import('@/components/mtipocarga/MtipocargaForm'));
Vue.component('mtipocarga-form-header', () => import('@/components/mtipocarga/MtipocargaFormHeader'));
Vue.component('mtipoequipamiento-grid', () => import('@/components/mtipoequipamiento/MtipoequipamientoGrid'));
Vue.component('mtipoequipamiento-form', () => import('@/components/mtipoequipamiento/MtipoequipamientoForm'));
Vue.component('mtipoequipamiento-form-header', () => import('@/components/mtipoequipamiento/MtipoequipamientoFormHeader'));
Vue.component('mtipoimplicado-grid', () => import('@/components/mtipoimplicado/MtipoimplicadoGrid'));
Vue.component('mtipoimplicado-form', () => import('@/components/mtipoimplicado/MtipoimplicadoForm'));
Vue.component('mtipoimplicado-form-header', () => import('@/components/mtipoimplicado/MtipoimplicadoFormHeader'));
Vue.component('mtipomanipulacion-grid', () => import('@/components/mtipomanipulacion/MtipomanipulacionGrid'));
Vue.component('mtipomanipulacion-form', () => import('@/components/mtipomanipulacion/MtipomanipulacionForm'));
Vue.component('mtipomanipulacion-form-header', () => import('@/components/mtipomanipulacion/MtipomanipulacionFormHeader'));
Vue.component('mtipotransporte-grid', () => import('@/components/mtipotransporte/MtipotransporteGrid'));
Vue.component('mtipotransporte-form', () => import('@/components/mtipotransporte/MtipotransporteForm'));
Vue.component('mtipotransporte-form-header', () => import('@/components/mtipotransporte/MtipotransporteFormHeader'));
Vue.component('mtipytamequipamiento-grid', () => import('@/components/mtipytamequipamiento/MtipytamequipamientoGrid'));
Vue.component('mtipytamequipamiento-form', () => import('@/components/mtipytamequipamiento/MtipytamequipamientoForm'));
Vue.component('mtipytamequipamiento-form-header', () => import('@/components/mtipytamequipamiento/MtipytamequipamientoFormHeader'));
Vue.component('mrescondicion-grid', () => import('@/components/mrescondicion/MrescondicionGrid'));
Vue.component('mrescondicion-form', () => import('@/components/mrescondicion/MrescondicionForm'));
Vue.component('mrescondicion-form-header', () => import('@/components/mrescondicion/MrescondicionFormHeader'));
Vue.component('mtipocanalcmnc-grid', () => import('@/components/mtipocanalcmnc/MtipocanalcmncGrid'));
Vue.component('mtipocanalcmnc-form', () => import('@/components/mtipocanalcmnc/MtipocanalcmncForm'));
Vue.component('mtipocanalcmnc-form-header', () => import('@/components/mtipocanalcmnc/MtipocanalcmncFormHeader'));
Vue.component('mgrupajeequipamiento-grid', () => import('@/components/mgrupajeequipamiento/MgrupajeequipamientoGrid'));
Vue.component('mgrupajeequipamiento-form', () => import('@/components/mgrupajeequipamiento/MgrupajeequipamientoForm'));
Vue.component('mgrupajeequipamiento-form-header', () => import('@/components/mgrupajeequipamiento/MgrupajeequipamientoFormHeader'));
Vue.component('notificacion-grid', () => import('@/components/notificacion/NotificacionGrid'));
Vue.component('notificacion-form', () => import('@/components/notificacion/NotificacionForm'));
Vue.component('notificacion-form-header', () => import('@/components/notificacion/NotificacionFormHeader'));
Vue.component('resolucion-grid', () => import('@/components/resolucion/ResolucionGrid'));
Vue.component('resolucion-form', () => import('@/components/resolucion/ResolucionForm'));
Vue.component('resolucion-form-header', () => import('@/components/resolucion/ResolucionFormHeader'));
Vue.component('mpuertolocal-grid', () => import('@/components/mpuertolocal/MpuertolocalGrid'));
Vue.component('mpuertolocal-form', () => import('@/components/mpuertolocal/MpuertolocalForm'));
Vue.component('mcontacto-grid', () => import('@/components/mcontacto/McontactoGrid'));
Vue.component('mcontacto-form', () => import('@/components/mcontacto/McontactoForm'));
Vue.component('mcontacto-form-header', () => import('@/components/mcontacto/McontactoFormHeader'));
Vue.component('notificacionresolucion-form', () => import('@/components/notificacionresolucion/NotificacionResolucionForm'));
Vue.component('resolucioncondicion-grid', () => import('@/components/resolucioncond/ResolucionCondicionGrid'));
Vue.component('resolucioncondicionquickedit-form', () => import('@/components/resolucioncond/ResolucionCondicionQuickEditForm'));
Vue.component('equipamiento-grid', () => import('@/components/equipamiento/EquipamientoGrid'));
Vue.component('equipamiento-form', () => import('@/components/equipamiento/EquipamientoForm'));
Vue.component('partida-grid', () => import('@/components/partida/PartidaGrid'));
Vue.component('partida-form', () => import('@/components/partida/PartidaForm'));
Vue.component('partida-form-header', () => import('@/components/partida/PartidaFormHeader'));
Vue.component('partidaequipamiento-grid', () => import('@/components/partidaequipamiento/PartidaequipamientoGrid'));
Vue.component('partidaequipamiento-form', () => import('@/components/partidaequipamiento/PartidaequipamientoForm'));
Vue.component('partidaequipamiento-form-header', () => import('@/components/partidaequipamiento/PartidaequipamientoFormHeader'));
Vue.component('partidaequipamientoquickedit-form', () => import('@/components/partidaequipamiento/PartidaequipamientoQuickEditForm'));
Vue.component('edimensajeerror-grid', () => import('@/components/edimensajeerror/EdimensajeerrorGrid'));
Vue.component('edimensaje-form-header', () => import('@/components/edimensaje/EdimensajeFormHeader'));
Vue.component('edimensajeauditoria-grid', () => import('@/components/edimensajeauditoria/EdimensajeauditoriaGrid'));
Vue.component('mimdg-revision-grid', () => import('@/components/mimdgrevision/MimdgRevisionGrid'));
Vue.component('mimdg-revision-form', () => import('@/components/mimdgrevision/MimdgRevisionForm'));
Vue.component('mterminal-grid', () => import('@/components/mterminal/MTerminalGrid'));
Vue.component('mterminal-form', () => import('@/components/mterminal/MTerminalForm'));
Vue.component('notificacioncontacto-grid', () => import('@/components/notificacioncontacto/NotificacioncontactoGrid'));
Vue.component('notificacioncontacto-form', () => import('@/components/notificacioncontacto/NotificacioncontactoForm'));
Vue.component('notificacioncontacto-form-header', () => import('@/components/notificacioncontacto/NotificacioncontactoFormHeader'));
Vue.component('magentenotificacionquickedit-form', () => import('@/components/notificacioncontacto/MAgenteNotificacionQuickEditForm'));
Vue.component('vmagentebytype-grid', () => import('@/components/vmagentebytype/VmagentebytypeGrid'));

// GEN_REGISTER_COMPONENT_END

const puiRoutes = [
	{
		path: '/',
		redirect: 'home'
	},
	{
		path: 'home',
		redirect: 'notificacion'
	},
	{
		path: 'usersettings',
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserSettingsForm.vue')
	},
	{
		path: 'puiaudit',
		component: () => import('pui9-admin/src/components/config/puiaudit/PuiAuditGrid.vue')
	},
	{
		path: 'session',
		component: () => import('pui9-admin/src/components/config/puisession/PuiSessionGrid.vue')
	},
	{
		path: 'puiuser',
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserGrid.vue')
	},
	{
		path: '(.*/)?puiuser/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserForm.vue')
	},
	{
		path: 'puiprofile',
		component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileGrid.vue')
	},
	{
		path: '(.*/)?puiprofile/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileForm.vue')
	},
	{
		path: 'puifunctionality',
		component: () => import('pui9-admin/src/components/admin/puifunctionality/PuiFunctionalityGrid.vue')
	},
	{
		path: 'puivariable',
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableGrid.vue')
	},
	{
		path: '(.*/)?puivariable/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableForm.vue')
	},
	{ path: 'puilanguage', component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageGrid.vue') },
	{
		path: '(.*/)?puilanguage/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageForm.vue')
	},
	{
		path: 'puidocument',
		component: () => import('pui9-documents/src/components/PuiDocumentGrid.vue')
	},
	{
		path: '(.*/)?puidocument/:method/:pk',
		props: true,
		component: () => import('pui9-documents/src/components/PuiDocumentForm.vue')
	},
	{
		path: 'puidocgentemplate',
		component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateGrid.vue')
	},
	{
		path: '(.*/)?puidocgentemplate/:method/:pk',
		props: true,
		component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateForm')
	},
	{ path: 'puiwidget', component: () => import('pui9-dashboard/src/components/puiwidget/PuiWidgetGrid.vue') },
	{ path: '(.*/)?puiwidget/:method/:pk', props: true, component: () => import('pui9-dashboard/src/components/puiwidget/PuiWidgetForm.vue') },
	{ path: 'puidashboard', component: () => import('pui9-dashboard/src/components/puidashboard/PuiDashboardGrid.vue') },
	{
		path: '(.*/)?puidashboard/:method/:pk',
		props: true,
		component: () => import('pui9-dashboard/src/components/puidashboard/PuiDashboardForm.vue')
	},
	{ path: 'puiwidgetoverview', component: () => import('pui9-dashboard/src/components/PuiWidgetOverview.vue') },
	{ path: 'puidashboardoverview', component: () => import('pui9-dashboard/src/components/PuiDashboardOverview.vue') }
];

const appRoutes = [
	// GEN_ROUTES_START
	{
		path: 'userapp',
		component: () => import('@/components/userapp/UserAppGrid')
	},
	{
		path: '(.*/)?userapp/:method/:pk',
		props: true,
		component: () => import('@/components/userapp/UserAppForm')
	},
	{
		path: 'mimdg',
		component: () => import('@/components/mimdg/MimdgGrid.vue')
	},
	{
		path: '(.*)mimdg/:method/:pk',
		props: true,
		component: () => import('@/components/mimdg/MimdgForm')
	},
	{
		path: 'magente',
		component: () => import('@/components/magente/MagenteGrid')
	},
	{
		path: '(.*)magente/:method/:pk',
		props: true,
		component: () => import('@/components/magente/MagenteForm')
	},
	{
		path: 'mpais',
		component: () => import('@/components/mpais/MpaisGrid')
	},
	{
		path: '(.*)mpais/:method/:pk',
		props: true,
		component: () => import('@/components/mpais/MpaisForm')
	},
	{
		path: 'mtiporesolucion',
		component: () => import('@/components/mtiporesolucion/MtiporesolucionGrid')
	},
	{
		path: '(.*)mtiporesolucion/:method/:pk',
		props: true,
		component: () => import('@/components/mtiporesolucion/MtiporesolucionForm')
	},
	{
		path: 'mgrupoenvasado',
		component: () => import('@/components/mgrupoenvasado/MgrupoenvasadoGrid')
	},
	{
		path: '(.*)mgrupoenvasado/:method/:pk',
		props: true,
		component: () => import('@/components/mgrupoenvasado/MgrupoenvasadoForm')
	},
	{
		path: 'mareaoperacion',
		component: () => import('@/components/mareaoperacion/MareaoperacionGrid')
	},
	{
		path: '(.*)mareaoperacion/:method/:pk',
		props: true,
		component: () => import('@/components/mareaoperacion/MareaoperacionForm')
	},
	{
		path: 'minstmarcaje',
		component: () => import('@/components/minstmarcaje/MinstmarcajeGrid')
	},
	{
		path: '(.*)minstmarcaje/:method/:pk',
		props: true,
		component: () => import('@/components/minstmarcaje/MinstmarcajeForm')
	},
	{
		path: 'mlocalidad',
		component: () => import('@/components/mlocalidad/MlocalidadGrid')
	},
	{
		path: '(.*)mlocalidad/:method/:pk',
		props: true,
		component: () => import('@/components/mlocalidad/MlocalidadForm')
	},
	{
		path: 'mtipobulto',
		component: () => import('@/components/mtipobulto/MtipobultoGrid')
	},
	{
		path: '(.*)mtipobulto/:method/:pk',
		props: true,
		component: () => import('@/components/mtipobulto/MtipobultoForm')
	},
	{
		path: 'mtipocarga',
		component: () => import('@/components/mtipocarga/MtipocargaGrid')
	},
	{
		path: '(.*)mtipocarga/:method/:pk',
		props: true,
		component: () => import('@/components/mtipocarga/MtipocargaForm')
	},
	{
		path: 'mtipoequipamiento',
		component: () => import('@/components/mtipoequipamiento/MtipoequipamientoGrid')
	},
	{
		path: '(.*)mtipoequipamiento/:method/:pk',
		props: true,
		component: () => import('@/components/mtipoequipamiento/MtipoequipamientoForm')
	},
	{
		path: 'mtipoimplicado',
		component: () => import('@/components/mtipoimplicado/MtipoimplicadoGrid')
	},
	{
		path: '(.*)mtipoimplicado/:method/:pk',
		props: true,
		component: () => import('@/components/mtipoimplicado/MtipoimplicadoForm')
	},
	{
		path: 'mtipomanipulacion',
		component: () => import('@/components/mtipomanipulacion/MtipomanipulacionGrid')
	},
	{
		path: '(.*)mtipomanipulacion/:method/:pk',
		props: true,
		component: () => import('@/components/mtipomanipulacion/MtipomanipulacionForm')
	},
	{
		path: 'mtipotransporte',
		component: () => import('@/components/mtipotransporte/MtipotransporteGrid')
	},
	{
		path: '(.*)mtipotransporte/:method/:pk',
		props: true,
		component: () => import('@/components/mtipotransporte/MtipotransporteForm')
	},
	{
		path: 'mtipytamequipamiento',
		component: () => import('@/components/mtipytamequipamiento/MtipytamequipamientoGrid')
	},
	{
		path: '(.*)mtipytamequipamiento/:method/:pk',
		props: true,
		component: () => import('@/components/mtipytamequipamiento/MtipytamequipamientoForm')
	},
	{
		path: 'mrescondicion',
		component: () => import('@/components/mrescondicion/MrescondicionGrid')
	},
	{
		path: '(.*)mrescondicion/:method/:pk',
		props: true,
		component: () => import('@/components/mrescondicion/MrescondicionForm')
	},
	{
		path: 'mtipocanalcmnc',
		component: () => import('@/components/mtipocanalcmnc/MtipocanalcmncGrid')
	},
	{
		path: '(.*)mtipocanalcmnc/:method/:pk',
		props: true,
		component: () => import('@/components/mtipocanalcmnc/MtipocanalcmncForm')
	},
	{
		path: 'mgrupajeequipamiento',
		component: () => import('@/components/mgrupajeequipamiento/MgrupajeequipamientoGrid')
	},
	{
		path: '(.*)mgrupajeequipamiento/:method/:pk',
		props: true,
		component: () => import('@/components/mgrupajeequipamiento/MgrupajeequipamientoForm')
	},
	{
		path: 'notificacion',
		component: () => import('@/components/notificacion/NotificacionGrid')
	},
	{
		path: '(.*)notificacion/:method/:pk',
		props: true,
		component: () => import('@/components/notificacion/NotificacionForm')
	},
	{
		path: 'notificacioncondocumento',
		component: () => import('@/components/notificacion/NotificacionConDocumentoGrid')
	},
	{
		path: 'resolucion',
		component: () => import('@/components/resolucion/ResolucionGrid')
	},
	{
		path: '(.*)resolucion/:method/:pk',
		props: true,
		component: () => import('@/components/resolucion/ResolucionForm')
	},
	{
		path: 'mpuertolocal',
		component: () => import('@/components/mpuertolocal/MpuertolocalGrid')
	},
	{
		path: '(.*)mpuertolocal/:method/:pk',
		props: true,
		component: () => import('@/components/mpuertolocal/MpuertolocalForm')
	},
	{
		path: 'mcontacto',
		component: () => import('@/components/mcontacto/McontactoGrid')
	},
	{
		path: '(.*)mcontacto/:method/:pk',
		props: true,
		component: () => import('@/components/mcontacto/McontactoForm')
	},
	{
		path: '(.*)notificacionres/:method/:pk',
		props: true,
		component: () => import('@/components/notificacionresolucion/NotificacionResolucionForm')
	},
	{
		path: 'resolucioncondicion',
		component: () => import('@/components/resolucioncond/ResolucionCondicionGrid')
	},
	{
		path: 'equipamiento',
		component: () => import('@/components/equipamiento/EquipamientoGrid')
	},
	{
		path: 'equipamiento/:method/:pk',
		props: true,
		component: () => import('@/components/equipamiento/EquipamientoForm')
	},
	{
		path: '(.*)/equipamiento/:method/:pk',
		props: true,
		component: () => import('@/components/equipamiento/EquipamientoForm')
	},
	{
		path: 'partida',
		component: () => import('@/components/partida/PartidaGrid')
	},
	{
		path: '(.*)partida/:method/:pk',
		props: true,
		component: () => import('@/components/partida/PartidaForm')
	},
	{
		path: 'mclase',
		component: () => import('@/components/mclase/MclaseGrid')
	},
	{
		path: '(.*)mclase/:method/:pk',
		props: true,
		component: () => import('@/components/mclase/MclaseForm')
	},
	{
		path: 'mcompatibilidad',
		component: () => import('@/components/mcompatibilidad/McompatibilidadGrid')
	},
	{
		path: '(.*)mcompatibilidad/:method/:pk',
		props: true,
		component: () => import('@/components/mcompatibilidad/McompatibilidadForm')
	},
	{
		path: 'mgrupommpp',
		component: () => import('@/components/mgrupommpp/MgrupommppGrid')
	},
	{
		path: '(.*)mgrupommpp/:method/:pk',
		props: true,
		component: () => import('@/components/mgrupommpp/MgrupommppForm')
	},
	{
		path: 'partidaequipamiento',
		component: () => import('@/components/partidaequipamiento/PartidaequipamientoGrid')
	},
	{
		path: '(.*)partidaequipamiento/:method/:pk',
		props: true,
		component: () => import('@/components/partidaequipamiento/PartidaequipamientoForm')
	},
	{
		path: 'mregla',
		component: () => import('@/components/mregla/MreglaGrid')
	},
	{
		path: '(.*)mregla/:method/:pk',
		props: true,
		component: () => import('@/components/mregla/MreglaForm')
	},
	{
		path: 'edimensaje',
		component: () => import('@/components/edimensaje/EdimensajeGrid')
	},
	{
		path: '(.*)edimensaje/:method/:pk',
		props: true,
		component: () => import('@/components/edimensaje/EdimensajeForm')
	},

	{
		path: 'mgrupoprioridad',
		component: () => import('@/components/mgrupoprioridad/MgrupoprioridadGrid')
	},
	{
		path: '(.*)mgrupoprioridad/:method/:pk',
		props: true,
		component: () => import('@/components/mgrupoprioridad/MgrupoprioridadForm')
	},
	{
		path: 'mimdgcategoriaestiba',
		component: () => import('@/components/mimdgcategoriaestiba/MimdgcategoriaestibaGrid')
	},
	{
		path: '(.*)mimdgcategoriaestiba/:method/:pk',
		props: true,
		component: () => import('@/components/mimdgcategoriaestiba/MimdgcategoriaestibaForm')
	},
	{
		path: 'mimdgrevision',
		component: () => import('@/components/mimdgrevision/MimdgRevisionGrid.vue')
	},
	{
		path: '(.*)mimdgrevision/:method/:pk',
		props: true,
		component: () => import('@/components/mimdgrevision/MimdgRevisionForm.vue')
	},
	{
		path: 'mrescategoriacondicion',
		component: () => import('@/components/mrescategoriacondicion/MResCategoriaCondicionGrid.vue')
	},
	{
		path: '(.*)mrescategoriacondicion/:method/:pk',
		props: true,
		component: () => import('@/components/mrescategoriacondicion/MResCategoriaCondicionForm.vue')
	},
	{
		path: 'mterminal',
		component: () => import('@/components/mterminal/MTerminalGrid.vue')
	},
	{
		path: '(.*)mterminal/:method/:pk',
		props: true,
		component: () => import('@/components/mterminal/MTerminalForm.vue')
	},
	{
		path: '(.*)showDocument_(.*)',
		component: () => import('@/components/notificacionDocumento/PuiDocumentPdfViewer.vue')
	},

	{
		path: 'notificacioncontacto',
		component: () => import('@/components/notificacioncontacto/NotificacioncontactoGrid')
	},
	{
		path: '(.*)notificacioncontacto/:method/:pk',
		props: true,
		component: () => import('@/components/notificacioncontacto/NotificacioncontactoForm')
	},
	{
		path: 'vmagentebytype',
		component: () => import('@/components/vmagentebytype/VmagentebytypeGrid')
	}
	// GEN_ROUTES_END
];

const puiNotFound = [{ path: '*', component: () => import('pui9-base/src/components/PuiNotFound') }];

function setAuthRequired(route) {
	return { ...route, meta: { ...route.meta, requiresAuth: true } };
}

const mergedRouter = {
	mode: 'history',
	hash: false,
	base: '/mmpp',
	routes: [
		{
			path: '/resetPassword',
			component: () => import('pui9-base/src/components/PuiUserResetPassword')
		},
		{
			path: '/login',
			component: () => import('@/components/login/SafetyLogin')
		},
		{
			path: '/keycloaklogin',
			component: () => import('pui9-login/src/components/PuiKeycloakLogin')
		},
		{
			path: `/`,
			component: () => import('pui9-base/src/components/PuiBaseLayout'),
			children: puiRoutes.concat(appRoutes).concat(puiNotFound).map(setAuthRequired)
		}
	]
};

export default new VueRouter(mergedRouter);
