const cloneConfiguration = function (gridConfiguration) {
	const newGridConfiguration = Object.assign({}, gridConfiguration);
	newGridConfiguration.configuration = Object.assign({}, gridConfiguration.configuration);
	const columns = [];
	const order = [];
	newGridConfiguration.configuration.order && newGridConfiguration.configuration.order.forEach(sortingRule => order.push(sortingRule));
	newGridConfiguration.configuration.order = order;
	newGridConfiguration.configuration.columns && newGridConfiguration.configuration.columns.forEach(column => columns.push(column));
	newGridConfiguration.configuration.columns = columns;
	return newGridConfiguration;
}

export default {
	loadDefaultGridConfiguration(store, modelName) {
		const currentConfig = store.getters.getCurrentGridConfiguration(modelName);
		if(!currentConfig){
			const defaultConfig = store.getters.getDefaultGridConfiguration(modelName);
			if (defaultConfig) {
				console.log(store)
				var gridConfiguration = cloneConfiguration(defaultConfig);
				store.commit('puiModelsSetCurrentGridConfiguration', { model: modelName, configuration: gridConfiguration });
			}
		}
	}
};
