<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App'

};
</script>

<style>
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}
</style>
