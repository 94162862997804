import store from '../store/store';
import puiI18n from '../i18n/i18n';
// DEBUG
//import store from '../store/store_dev';
//import puiI18n from '../i18n/i18n_dev';
import Vue from 'vue';
import notification, { setVueInstance } from 'pui9-notifications/src/notification';
setVueInstance(Vue);

const modulo = function(valor, divisor) {
	var resto = 0;
	var dividendo = 0;
	for (var i = 0; i < valor.length; i += 10) {
		dividendo = resto + '' + valor.substr(i, 10);
		resto = dividendo % divisor;
	}
	return resto;
};


class AppUtils {
	constructor() {
		// do nothing
	}

	fixedAndRoundUp(value, scale) {
		const roundUpFixNumber = 0.00499999999; // Forces to round up, fix javascript handling number problems
		return (value + roundUpFixNumber).toFixed(scale);
	}

	isEmpty(...values) {
		return values.some(
			value =>
				value === undefined ||
				value === null ||
				(typeof value === 'string' && value.trim() === '') ||
				(Array.isArray(value) && value.length === 0)
		);
	}

	isNullOrUndefined(...values) {
		return values.some(value => value === undefined || value === null);
	}

	cloneObject(object) {
		return JSON.parse(JSON.stringify(object));
	}

	isNumber(strNumber) {
		return !isNaN(strNumber);
	}

	setStoredModelVariable(modelName, key, value) {
		const storedModel = store.getters.getModelByName(modelName);
		storedModel && (storedModel[key] = value);
	}

	notifyError(title, message) {
		let notificationMessage = message;
		if (message.response && message.response.data) {
			notificationMessage = message.response.data.message;
		}
		const messageEvent = notificationMessage || puiI18n.t('puiaction.notifyError');
		const titleEvent = puiI18n.t('puiaction.notifyTitle') + (title ? ' > ' + title : '');
		notification.error(messageEvent, titleEvent);
	}

	notifySuccess(title, message) {
		const messageEvent = message || puiI18n.t('puiaction.notifySuccess');
		const titleEvent = puiI18n.t('puiaction.notifyTitle') + (title ? ' > ' + title : '');
		notification.success(messageEvent, titleEvent);
	}

	/**
	 * Calculate control digit number of a bank account
	 * - entity/bank: the bank key
	 * - office: the office key
	 * - accountnumber: the accountnumber
	 * #typedef {{bank: string, office: string, accountnumber: string}}
	 * #public
	 */
	calcularDigitoControl(bank, office, accountnumber) {
		if (this.isEmpty(bank) || this.isEmpty(office) || this.isEmpty(accountnumber)) {
			return result;
		}
		let Pesos = new Array(6, 3, 7, 9, 10, 5, 8, 4, 2, 1);
		let result = null;
		let iTemp = 0;
		let bankAndOffice = `${bank}${office}`;

		for (let n = 0; n <= 7; n++) {
			iTemp = iTemp + bankAndOffice.substr(7 - n, 1) * Pesos[n];
		}
		result = 11 - (iTemp % 11);
		if (result > 9) {
			result = 1 - (result % 10);
		}
		iTemp = 0;
		for (let n = 0; n <= 9; n++) {
			iTemp = iTemp + accountnumber.substr(9 - n, 1) * Pesos[n];
		}
		iTemp = 11 - (iTemp % 11);
		if (iTemp > 9) {
			iTemp = 1 - (iTemp % 10);
		}
		result = result * 10 + iTemp;

		return result.toString().length == 1 ? '0' + result : result;
	}

	/**
	 * Calculate control digit number of a IBAN number
	 * - iban: the iban number (ESxx xxxx xxxx xx xxxxxxxxxx)
	 * #typedef {{iban: string}}
	 * #public
	 */
	calcularDigitoControlIBAN(iban) {
		const codigoPais = iban.substr(0, 2).toUpperCase();
		const cc = iban.substr(4);
		// cada letter de pais tiene un valor
		var valoresPaises = {
			A: '10',
			B: '11',
			C: '12',
			D: '13',
			E: '14',
			F: '15',
			G: '16',
			H: '17',
			I: '18',
			J: '19',
			K: '20',
			L: '21',
			M: '22',
			N: '23',
			O: '24',
			P: '25',
			Q: '26',
			R: '27',
			S: '28',
			T: '29',
			U: '30',
			V: '31',
			W: '32',
			X: '33',
			Y: '34',
			Z: '35'
		};

		// reemplazamos cada letter por su valor numerico y ponemos los valores mas dos ceros al final de la cuenta
		var dividendo = cc + valoresPaises[codigoPais.substr(0, 1)] + valoresPaises[codigoPais.substr(1, 1)] + '00';

		// Calculamos el modulo 97 sobre el valor numerico y lo restamos al valor 98
		return 98 - modulo(dividendo, 97);
	}

	formatNumber(val) {
		if (val === null) {
			return '';
		}

		const thousandSeparator = store.getters.thousandSeparator;
		const decimalSeparator = store.getters.decimalSeparator;

		if (val.toString().includes('.')) {
			const parts = val.toString().split('.');

			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

			return parts.join(decimalSeparator);
		}

		return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
	}

	getFilenameFromHeaders(headers) {
		const disposition = headers['content-disposition'];
		if (disposition && disposition.indexOf('attachment') !== -1) {
			var filenameRegex = /filename=((['"]).*?\2|[^;\n]*)/;
			var matches = filenameRegex.exec(disposition);
			if (matches != null && matches[1]) {
				return matches[1].replace(/['"]/g, '');
			}
		}
		return null;
	}
}

export default new AppUtils();
